@import "../../resources/scss/variables";

@font-face {
  font-family: "Lato Regular";
  src: url("../../resources/fonts/Lato-Regular.ttf");
}

@font-face {
  font-family: "Montserrat Regular";
  src: url("../../resources/fonts/Montserrat-Regular.ttf");
}

#footer {
  padding: 30px 0;
  margin-top: 30px;
  font-family: "Montserrat Regular";
  color: $color-azul;
  width: 100%;

  & .logo-connect {
    width: 100%;
    // text-align: center;
    & img {
      max-width: 200px;
    }
  }

  & .titulo-menu {
    font-size: 12px;
    font-family: "Montserrat Regular";
    font-weight: bold;
    margin-bottom: 10px;
    color: $color-azul;
  }

  & ul {
    list-style-type:none;
  }

  & .option {
    font-family: "Montserrat Regular";
    color: $color-azul;
    font-size: 12px;
    margin-right: 15px;
    margin-bottom: 5px;
    text-align: left;
    cursor: pointer;
    // width: 60px;

    &:hover {
      text-decoration: underline;
    }

    // & .option-right {
    //   margin-right: 10px;
    //   cursor: pointer;
    //   &:hover {
    //     text-decoration: underline;
    //   }
  }
  & .conatiner-options {
    
      }
    display: inline-block;

  & .out-sesion {
    text-align: right;
  }

  & .divider {
    height: 1px;
    width: 100%;
    background: #ccc;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  & .MuiButton-root {
    width: 120px;

    color: $color-azul;
    padding: 10px;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    border-radius: 10px;
    text-transform: capitalize;
    &:hover {
      background-color: transparent !important;
    }

  }

  & .all-rights-reserved {
    color: $color-azul;
    font-size: 11px;
  }

  & .social-media {
    text-align: right;

    & .follow-us {
      color: $color-azul;
      font-size: 11px;

      & img {
        margin-left: 10px;
        width: 25px;
      }
    }
  }

  
}

// celular
@media screen and (max-width: 425px) and (orientation: portrait) {
  #footer {
    text-align: center;

    & .logo-connect {
      margin-bottom: 15px;
    }

    & .btn-entrar {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    & .out-sesion {
      text-align: center;
      margin-top: 15px;
    }

    & .social-media {
      text-align: center;

      & .follow-us {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 880px) and (orientation: landscape) {
  #footer {
    text-align: center;

    & .social-media {
      text-align: center;
    }

    & .out-sesion {
      text-align: center;
      margin-top: 15px;
    }
  }
}

// tablet
@media screen and (min-width: 881px) and (max-width: 1440px) and (orientation: landscape) {}