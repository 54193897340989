#coming-soon {
    overflow: hidden;
    padding: 15px;

    & .container-coming-soon {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left !important;
        height: 100vh;
        flex-wrap: wrap;

        & .logo-mindfulness {
            margin-bottom: 50px;
        }

        & p {
            font-size: 22px;
        }
    }
}

@media screen and (max-width: 425px) and (orientation: portrait) {
    #coming-soon {
        & .container-coming-soon {
            & .logo-mindfulness {
                width: 200px;
                margin-bottom: 30px;
            }

            & .text-coming-soon {
                width: 100%;
            }

            & p {
                font-size: 18px;
            }
        }
    }
}

@media screen and (max-width: 880px) and (orientation: landscape) {
    #coming-soon {
        & .container-coming-soon {
            & .logo-mindfulness {
                width: 200px;
                margin-bottom: 30px;
            }

            & .text-coming-soon {
                width: 100%;
            }

            & p {
                font-size: 18px;
            }
        }
    }
}

@media screen and (min-width: 881px) and (max-width: 1440px) and (orientation: landscape) {}