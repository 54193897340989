@font-face {
    font-family: "Poppins Regular";
    src: url("../../../resources/fonts/Poppins-Regular.ttf");
}

$color-background-header: #181717;
$color-background-contenedor-menssages: #282535;
$color-background-input: #3a3a3a;
$color-brackground-button: #38388f;
$color-background-messages-sent: #0b93f6;
$color-background-messages-received: #e5e5ea;
$color-background-scroll: red;
$color-background-line-scroll: #1e1e24;

#chat {
    font-family: "Poppins Regular";

    .App {
        text-align: center;
        // max-width: 728px;
        height: 450px;
        margin: 0 auto;

        & .titulo-chat {
            background-color: $color-background-header;
            // height: 10vh;
            min-height: 50px;
            color: white;
            // position: fixed;
            width: 100%;
            max-width: 100%;
            // top: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            z-index: 99;
            padding: 10px;
            box-sizing: border-box;
        }

        & section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            // min-height: 100vh;
            height: 100%;
            background-color: $color-background-contenedor-menssages;
        }
    }

    main {
        padding: 10px;
        height: 80vh;
        margin: 0;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;

        &::-webkit-scrollbar {
            width: 0.25rem;
            height: 0px;
        }

        &::-webkit-scrollbar-track {
            background: $color-background-line-scroll;
        }

        &::-webkit-scrollbar-thumb {
            background: $color-background-scroll;
        }
    }

    form {
        // height: 10vh;
        // position: fixed;
        // bottom: 0;
        background-color: $color-background-header;
        // width: 100%;
        // max-width: 728px;
        display: flex;
        font-size: 1.5rem;

        & button {
            width: 20%;
            background-color: $color-brackground-button;
        }
    }

    input {
        line-height: 1.5;
        width: 100%;
        font-size: 16px;
        background: $color-background-input;
        color: white;
        outline: none;
        border: none;
        padding: 0 10px;
        font-family: "Poppins Regular";
    }

    button {
        background-color: $color-brackground-button;
        /* Green */
        border: none;
        color: white;
        padding: 15px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        cursor: pointer;
        font-size: 16px;
    }

    button:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    ul,
    li {
        text-align: left;
        list-style: none;
    }

    p {
        max-width: 500px;
        margin-bottom: 12px;
        // line-height: 24px;
        padding: 10px;
        border-radius: 15px;
        position: relative;
        color: white;
        text-align: initial;
        font-size: 13px;
        word-break: break-word;
    }

    .message {
        display: flex;
        align-items: center;
    }


    .sent {
        flex-direction: row-reverse;
    }

    .sent p {
        color: white;
        background: $color-background-messages-sent;
        align-self: flex-end;
    }

    .received p {
        background: $color-background-messages-received;
        color: black;
    }

    img {
        width: 40px;
        min-height: 40px;
        min-width: 40px;
        height: 40px;
        border-radius: 50%;
        margin: 2px 5px;
    }

    & .fecha {
        font-size: 10px;
    }

    & .vetado {
        opacity: .6;
        // display:  none;
    }
}