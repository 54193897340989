$color-background-input: #3a3a3a;

#panel-chat {
    color: #000;
    padding-top: 5%;
    background: #eee;
    width: 100vw;
    height: 100vh;
    overflow: scroll;

    & .contenedor-mensajes {
        border: 1px solid #ccc;
        height: 500px;
        width: 100%;
        background-color: #fff;
        

        &::-webkit-scrollbar {
            width: 0.25rem;
            height: 0px;
        }

        &::-webkit-scrollbar-track {
            background: $color-background-input;
        }

        &::-webkit-scrollbar-thumb {
            background: $color-background-input;
        }

        & .noLeido {
            background-color: #6cc3fc;
            color: #fff;
            font-weight: bold;
        }

        & .noLeido {
            background: #7dc4f89e;
            font-weight: bold;
        }

        & .contenedor-mensaje-usuario {
            // display: flex;
            // align-items: center;
            // width: 100%;
            padding: 10px;
            cursor: pointer;
            border-bottom: 1px solid #ccc;
           
            
            & img {
                width: 40px;
                min-height: 40px;
                min-width: 40px;
                height: 40px;
                border-radius: 50%;
                margin: 2px 5px;
            }
            
            & span {
                align-self: flex-end;
                font-size: 14px;
            }
        }
    }
    
    & .visor-mensajes {
        border: 1px solid #ccc;
        height: 500px;
        width: 100%;
        background-color: #fff;
        
        
        &::-webkit-scrollbar {
            width: 0.25rem;
            height: 0px;
        }

        &::-webkit-scrollbar-track {
            background: $color-background-input;
        }

        &::-webkit-scrollbar-thumb {
            background: $color-background-input;
        }
    }
}