@import "../../../resources/scss/variables";

#section-two {
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: $color-azul;
  padding: 25px 0;
  
  & p {
    color: #fff !important;
  }

  .btn {
    line-height: 50px !important;
    height: 50px !important;
    text-align: center !important;
    width: 250px !important;
    cursor: pointer !important;
  }

  .btn-one {
    color: #fff !important;
    transition: all 0.3s !important;
    position: relative !important;
  }

  .btn-one span {
    transition: all 0.3s !important;
  }

  .btn-one::before {
    content: '' !important;
    position: absolute !important;
    bottom: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    z-index: 1 !important;
    opacity: 0 !important;
    transition: all 0.3s !important;
    border-top-width: 1px !important;
    border-bottom-width: 1px !important;
    border-top-style: solid !important;
    border-bottom-style: solid !important;
    border-top-color: #fff !important;
    border-bottom-color: #fff !important;
    transform: scale(0.1, 1) !important;
  }

  .btn-one:hover span {
    letter-spacing: 2px !important;

  }

  .btn-one:hover::before {
    opacity: 1 !important;
    transform: scale(1, 1) !important;
  }

  .btn-one::after {
    content: '' !important;
    position: absolute !important;
    bottom: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    z-index: 1 !important;
    transition: all 0.3s !important;
    background-color: rgba(255, 255, 255, 0.1) !important;
  }

  .btn-one:hover::after {
    opacity: 0 !important;
    transform: scale(0.1, 1) !important;
  }

    .alice-carousel__dots-item:not(.__custom):hover,
    .alice-carousel__dots-item:not(.__custom).__active {
      background: $color-azul !important;
    }
}