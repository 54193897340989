@import "../../resources/scss/variables";

@font-face {
  font-family: "Lato Regular";
  src: url("../../resources/fonts/Lato-Regular.ttf");
}

@font-face {
  font-family: "Montserrat Regular";
  src: url("../../resources/fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat Light";
  src: url("../../resources/fonts/Montserrat-Light.ttf");
}

#home {
  overflow: hidden;
  margin-bottom: 40px;

  & h1 {
    font-size: 36px;
    color: $color-azul;
  }

  & p {
    color: $color-azul;
  }

  #section-kick-off {
    color: $color-azul;

    & .target-conference {
      font-size: 12px;
      width: 265px;
      height: 230px;
      background: #fff;
      padding: 15px;

      & .date-conference {
        font-size: 12px;
        margin-bottom: 10px;
        font-family: "Montserrat Regular";
      }

      & .name-conference {
        color: $color-azul;
        font-size: 18px;
        margin-bottom: 15px;
        font-family: "Montserrat Regular";
      }

      & .icono-info {
        width: 150px;
        margin-right: 15px;
      }

      & .name-conferencista {
        font-size: 14px;

        & .job-conferencista {
          color: $color-azul;
          font-weight: normal;
          font-size: 13px;
        }
      }

      & p {
        color: $color-azul;

        margin-bottom: 15px;
        font-family: "Montserrat Regular";
      }
    }
  }

  #section-last-event {
    background-color: #F8F7F7;
    padding: 20px 0;

    & .fullwrap {
      position: relative;
      /* required for (c2) */
      max-width: 100%;
      /* optional */
      overflow: hidden;
      margin: 0 10px;
    }

    /* (B) RESPONSIVE IMAGE */
    & .fullwrap img {
      width: 100%;
    }

    /* (C) CAPTION */
    & .fullcap {
      /* (C1) DIMENSIONS */
      box-sizing: border-box;
      width: 100%;
      height: 100%;

      /* (C2) POSITION */
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      padding-top: 35%;

      /* (C3) COLORS */
      background-color: rgba(82, 100, 125, 0.7);
      color: white;
    }

    /* (D) SHOW/HIDE */
    & .fullcap {
      visibility: none;
      opacity: 0;
      transition: opacity 0.3s;

      & .titulo {
        font-weight: bold;
        margin-bottom: 20px;
        display: none;
      }
    }

    & .fullwrap:hover .fullcap {
      visibility: visible;
      opacity: 1;
    }

    .btn {
      line-height: 50px !important;
      height: 50px !important;
      text-align: center !important;
      width: 250px !important;
      cursor: pointer !important;
    }

    .btn-one {
      color: #fff !important;
      transition: all 0.3s !important;
      position: relative !important;
    }

    .btn-one span {
      transition: all 0.3s !important;
    }

    .btn-one::before {
      content: '' !important;
      position: absolute !important;
      bottom: 0 !important;
      left: 0 !important;
      width: 100% !important;
      height: 100% !important;
      z-index: 1 !important;
      opacity: 0 !important;
      transition: all 0.3s !important;
      border-top-width: 1px !important;
      border-bottom-width: 1px !important;
      border-top-style: solid !important;
      border-bottom-style: solid !important;
      border-top-color: #fff !important;
      border-bottom-color: #fff !important;
      transform: scale(0.1, 1) !important;
    }

    .btn-one:hover span {
      letter-spacing: 2px !important;

    }

    .btn-one:hover::before {
      opacity: 1 !important;
      transform: scale(1, 1) !important;
    }

    .btn-one::after {
      content: '' !important;
      position: absolute !important;
      bottom: 0 !important;
      left: 0 !important;
      width: 100% !important;
      height: 100% !important;
      z-index: 1 !important;
      transition: all 0.3s !important;
      background-color: rgba(255, 255, 255, 0.1) !important;
    }

    .btn-one:hover::after {
      opacity: 0 !important;
      transform: scale(0.1, 1) !important;
    }
  }
}

// celular
@media screen and (max-width: 425px) and (orientation: portrait) {
  #home {
    & #section-last-event {
      & .fullwrap img {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 880px) and (orientation: landscape) {
  #home {}
}

// tablet
@media screen and (min-width: 881px) and (max-width: 1440px) and (orientation: landscape) {
  #home {}
}