@import "../../resources/scss/variables";

@font-face {
  font-family: "Lato Regular";
  src: url("../../resources/fonts/Lato-Regular.ttf");
}

@font-face {
  font-family: "Montserrat Regular";
  src: url("../../resources/fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat Light";
  src: url("../../resources/fonts/Montserrat-Light.ttf");
}

#speakers {
  font-family: "Montserrat Regular";

  & p {
    color: $color-azul;
  }

  & .container-speaker {
    font-family: "Montserrat Regular";
    position: relative;
    border-radius: 15px;
    height: 350px;
    color: $color-azul !important;
    -webkit-box-shadow: 0px 1px 10px -1px rgba(0, 0, 0, 0.61);
    box-shadow: 0px 1px 10px -1px rgba(0, 0, 0, 0.61);
    background-color: #fff;

    // &:hover {
    //   -webkit-box-shadow: 1px 7px 20px 8px rgba(0, 0, 0, 0.35);
    //   box-shadow: 1px 7px 20px 8px rgba(0, 0, 0, 0.35);
    //   transform: scale(1.1);
    // }

    & .container-img {
      overflow: hidden;
      
      & .img-conferencista {
        width: 100%;
        height: 230px;
        // background-position: center top;
        // background-size: cover;
        // background-repeat: no-repeat;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;

        transform-origin: 0 0;
        transition: transform .25s,
          visibility .25s ease-in;
      }

      &:hover .img-conferencista {
        transform: scale(1.12);
      }
    }

    & .logo {
      position: absolute;
      top: 165px;
      right: 6px;
      width: 60px;
    }

    & .name {
      text-align: left;
      color: $color-azul;
      font-size: 16px;
      font-weight: bold;
      padding-top: 15px;
      padding-left: 15px;
    }

    & .job {
      font-size: 12px;
      font-family: "Montserrat Light";
      color: $color-azul;
      text-align: left;
      padding-left: 15px;
    }

    & .social-media {
          position: absolute;
          bottom: 0;
          left: 2%;

      & img {
        cursor: pointer;
        margin-right: 5px;
      }
    }
  }
}

@media screen and (max-width: 425px) and (orientation: portrait) {
  #speakers {}
}

@media screen and (max-width: 767px) and (orientation: landscape) {
  #speakers {}
}