@import "../../resources/scss/variables";

@font-face {
  font-family: "Lato Regular";
  src: url("../../resources/fonts/Lato-Regular.ttf");
}

@font-face {
  font-family: "Poppins Regular";
  src: url("../../resources/fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Poppins Regular";
  src: url("../../resources/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Roboto Regular";
  src: url("../../resources/fonts/Roboto-Regular.ttf");
}

#login {
  height: 100vh;
  overflow: hidden;
  color: #fff;
  font-size: 18px;
  font-family: "Poppins Regular";

  // proximamente
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // text-align: left !important;
  // height: 100vh;
  // flex-wrap: wrap;

  & .helperText {
    color: $color-azul;
    margin-top: -7px;
    font-family: "Poppins Regular";
  }

  & form {
    overflow: hidden;
  }

  & .form-registro {
    width: 60%;
    margin: auto;
    overflow: hidden;
  }

  & .form-registro {
    width: 60%;
    margin: auto;
    overflow: hidden;
  }

  & .container-logo {
    background-image: url(../../resources/images/login/fondo-derecho.svg);
    font-family: "Montserrat Light";
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    height: 100vh;
    padding: 18px;

    & .overlap {
      margin-top: 80px;
    }

    & h1 {
      font-family: "Montserrat Regular" !important;
      font-size: 48px;
    }

    & p {
      padding: 0 80px;
      font-family: "Poppins Regular";
    }
  }

  & .container-form-login {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left !important;
    height: 100vh;
    flex-wrap: wrap;

    & .text-form {
      font-size: 44px;
      color: $color-azul;
      // background: linear-gradient(89.86deg, #1426ed 0.12%, #08041c 98.29%);
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
      // background-clip: text;
      // text-fill-color: transparent;
      margin-bottom: 20px;
      font-weight: bold;
      // font-family: "Poppins Regular";
    }

    & .text-reset {
      color: $color-azul;
      font-size: 16px;
      margin-bottom: 20px;
    }

    & .error {
      background: #ffeaea;
      color: $color-azul;
      padding: 5px 20px;
      font-size: 14px;
      margin-bottom: 10px;
    }

    & .register {
      color: $color-azul;
      font-size: 12px;
      margin-left: 30px;

      & .register-text {
        color: $color-azul;
        font-weight: bold;
        cursor: pointer;
        // font-family: "Montserrat Regular";
      }
    }

    & .reset-password {
      color: $color-azul;
      font-size: 14px;
      margin-top: 60px;
      cursor: pointer;
    }

    & .MuiButton-root {
      width: 170px;
      // background: $color-azul;
      color: $color-azul !important;
      padding: 5px;
      cursor: pointer;
      font-size: 14px;
      text-align: center;
      border-radius: 0px;
      text-transform: capitalize;
      font-weight: bold;
      font-family: "Poppins Regular";

      &:hover {
        background: transparent !important;
        color: $color-azul !important;
      }
    }

    & .MuiInputBase-root {
      color: red !important;
      font-family: "Poppins Regular";
    }

    .MuiInputBase-input {
      color: $color-azul !important;
      font-family: "Poppins Regular";
    }

    & .MuiInput-underline {
      &::after {
        border-bottom: 2px solid #fff !important;
      }

      &::before {
        border-bottom: 2px solid #fff !important;
      }
    }

    & .MuiFormLabel-root {
      color: $color-azul !important;
      font-family: "Montserrat Regular" !important;
    }

    & .MuiInputLabel-outlined.MuiInputLabel-shrink {
      transform: translate(0px, -18px) scale(1) !important;
      font-family: "Montserrat Regular" !important;
      color: $color-azul !important;
    }

    & .MuiOutlinedInput-input {
      // background: transparent linear-gradient(90deg, $color-azul 0%, $color-azul 24%, $color-azul 68%, $color-azul 100%) 0% 0% no-repeat padding-box !important;
      color: $color-azul !important;
      background: $color-azul !important;
      border-radius: 10px !important;
      font-family: "Montserrat Regular" !important;
      // text-transform: uppercase;
    }

    & .MuiInputLabel-standard {
      color: $color-azul !important;
      font-size: 16px;
      font-family: "Montserrat Regular" !important;
    }

    & .MuiInputBase-root {
      margin-bottom: 10px;
      border-radius: 20px;
    }

    & .MuiFilledInput-underline {

      &::before,
      &::after {
        border: none !important;
      }
    }

    & .MuiOutlinedInput-notchedOutline {
      border: #fff !important;
    }

    & .MuiFormLabel-root.Mui-focused {
      color: $color-azul !important;
      font-weight: normal;
      font-size: 14px;
    }

    & .MuiTypography-body1 {
      font-family: "Montserrat Regular" !important;
    }

    & label.MuiFormControlLabel-root {
      color: $color-azul !important;
      font-family: "Poppins Regular";

    }
  }
}

@media screen and (max-width: 765px) and (orientation: portrait) {
  #login {
    overflow-y: scroll;
    overflow-x: hidden;

    & .form-registro {
      width: 100%;
    }

    & .container-logo {
      & .welcome-text {
        & p {
          padding: 0;
          font-size: 15px;
        }
      }
    }

    & .container-form-login {
      display: block;
      padding: 40px 15px;
      height: auto;

      & .MuiButton-root {
        margin: auto;
      }

      & .space-footer-form {
        display: grid;

        & .register {
          text-align: center;
          margin-top: 20px;
          margin-left: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) and (orientation: landscape) {
  #login {
    overflow-y: scroll;
    overflow-x: hidden;

    & .form-registro {
      width: 60%;
      margin: auto;
      overflow: hidden;
    }

    & .form-registro {
      width: 90%;
      margin: auto;
      overflow: hidden;
    }

    & .container-logo {
      height: auto;

      & .welcome-text h1 {
        font-family: "Poppins Regular";
        font-size: 35px;
      }

      & .welcome-text p {
        padding: 0;
        font-size: 18px;
      }
    }

    & .container-form-login {
      display: block;
      padding: 40px 15px;
      height: auto;

      & .MuiButton-root {
        margin: auto;
      }

      & .space-footer-form {
        display: grid;

        & .register {
          text-align: center;
          margin-top: 20px;
          margin-left: 20px;
        }
      }
    }
  }
}