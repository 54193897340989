@import "../../resources/scss/variables";

@font-face {
  font-family: "Montserrat Regular";
  src: url("../../resources/fonts/Montserrat-Regular.ttf");
}

body {
  margin: 0;

  font-family: "Montserrat Regular";
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  font-size: 18px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root::after {
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: -1;
  background-repeat: no-repeat;
  background-position-y: 100%;
  background-position-x: center;
  background-size: cover;
  background-position: center;
  background-color: #fff;
  // background-image: url(../../resources/images/bg.svg);
}

.MuiButton-root {
  &:hover {
    background: transparent !important;
    color: $color-azul !important;
  }
}

.btn {
  line-height: 50px !important;
  height: 50px !important;
  text-align: center !important;
  width: 170px !important;
  cursor: pointer !important;
}

.btn-one {
  color: $color-azul !important;
  transition: all 0.3s !important;
  position: relative !important;
}

.btn-one span {
  transition: all 0.3s !important;
}

.btn-one::before {
  content: '' !important;
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 1 !important;
  opacity: 0 !important;
  transition: all 0.3s !important;
  border-top-width: 1px !important;
  border-bottom-width: 1px !important;
  border-top-style: solid !important;
  border-bottom-style: solid !important;
  border-top-color: $color-azul !important;
  border-bottom-color: $color-azul !important;
  transform: scale(0.1, 1) !important;
}

.btn-one:hover span {
  letter-spacing: 2px !important;

}

.btn-one:hover::before {
  opacity: 1 !important;
  transform: scale(1, 1) !important;
}

.btn-one::after {
  content: '' !important;
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 1 !important;
  transition: all 0.3s !important;
  background-color: rgba(255, 255, 255, 0) !important;
}

.btn-one:hover::after {
  opacity: 0 !important;
  transform: scale(0.1, 1) !important;
}

p,
h1,
h2,
h3 {
  font-family: "Montserrat Regular";
}

.full {
  width: 100%;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.bold {
  font-weight: bold !important;
}

.cursor {
  cursor: pointer;
}

.padre {
  display: table;

  & .hijos {
    display: table-cell;
    vertical-align: middle;
  }
}

.padres {
  display: table;

  & .hijoss {
    display: center;
    vertical-align: middle;
  }
}

.flex {
  display: flex;
}

.contenedor-streaming {
  width: 100%;
  text-align: center;
  // margin-bottom: 60px;

  & .estilo-streaming {
    width: 100%;
    height: 450px;
  }
}

.titulos {
  font-size: 36px;
  color: $color-azul;
  font-weight: 700;
}

.blanco {
  color: #fff !important;
}

/* width scroll */
::-webkit-scrollbar {
  width: 0px;
}

.mb30 {
  margin-bottom: 30px;
}

// celular
@media screen and (max-width: 425px) and (orientation: portrait) {
  .titulos {
    font-size: 22px;
  }

  .contenedor-streaming {
    & .estilo-streaming {
      width: 100%;
      height: auto;
    }
  }
}

@media screen and (max-width: 880px) and (orientation: landscape) {
  .titulos {
    font-size: 22px;
  }
}

// tablet
@media screen and (min-width: 881px) and (max-width: 1440px) and (orientation: landscape) {}