@import "../../resources/scss/variables";

#aboutUs {
    margin-top: 60px;
    margin-bottom: 60px;
    font-size: 18px;
    color: $color-azul !important;

    & .logo-about-us {
        width: 250px;
    }
}
